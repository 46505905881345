<template>
  <div class="modifyData">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm" v-show="!domainsDialog">
      <el-form-item class="marBot0">
        <h2>编辑基本信息</h2>
      </el-form-item>
      <el-form-item prop="name">
        <el-input v-model="ruleForm.name" placeholder="姓名："></el-input>
      </el-form-item>
      <el-form-item prop="company">
        <el-input v-model="ruleForm.company" placeholder="医院/单位："></el-input>
      </el-form-item>
      <el-form-item prop="job">
        <el-input v-model="ruleForm.job" placeholder="职称："></el-input>
      </el-form-item>
      <el-form-item>
        <div @click="changeDomains" class="domainsBox">
          <span class="title" :class="domainList.length > 0 ? 'checkedDomain' : ''">{{domainList.length > 0 ? '已选择：':'关注领域：'}}</span>
          <span class="domainList">
            <span v-for="item in domainList" :key="item.id">{{item.name}}</span>
          </span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">完成</el-button>
      </el-form-item>
    </el-form>

    <div class="domainsDialog" v-show="domainsDialog">
      <img src="@/assets/img/goback_icon.svg" @click="domainsDialog = false">
      <el-checkbox-group v-model="checkedDomains" @change="handleCheckedDomainsChange">
        <el-checkbox v-for="item in domainsCheck" :label="item.id" :key="item.id" @change="labelChecked(item)">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      <el-button @click="domainsDialog = false">确认</el-button>
    </div>
  </div>
</template>
<script>
import { getDomains, editModifyData } from "@/api/getData";
export default {
  name: "ModifyData",
  data() {
    return {
      ruleForm: {
        name: '',
        company: '',
        job: '',
        domains: []
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入医院', trigger: 'blur' }
        ],
        job: [
          { required: true, message: '请输入职称', trigger: 'blur' }
        ],
        domains: [
          { required: true, message: '请选择关注领域', trigger: 'blur' }
        ]
      },
      domainsDialog: false,
      checkedDomains: [],
      domainsCheck: [],
      domainList: []
    }
  },
  methods: {
    //回显
    echo() {
      for (var i in this.vDomains) {
        console.log(this.vDomains)
        for (var x in this.domainsCheck) {
          if (this.vDomains[i] == this.domainsCheck[x].id) {
            this.domainList.push(this.domainsCheck[x])
          }
        }
      }
      this.ruleForm.name = this.vName
      this.ruleForm.company = this.vCompany
      this.ruleForm.job = this.vJob
      this.checkedDomains = this.vDomains
    },
    //选择/取消check
    labelChecked(domain) {
      if (this.domainList.indexOf(domain) != -1) {
        this.domainList.splice(this.domainList.indexOf(domain), 1)
      } else {
        this.domainList.push(domain)
      }
      console.log(this.domainList)
    },
    handleCheckedDomainsChange() {
      // console.log(this.checkedDomains)
    },
    //关注领域dialog
    changeDomains() {
      this.domainsDialog = true
    },
    //领域列表
    getDomains() {
      getDomains().then(res => {
        this.domainsCheck = res.result
        this.echo()
      })
    },
    //提交注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            name: this.ruleForm.name,
            company: this.ruleForm.company,
            job: this.ruleForm.job,
            domains: this.checkedDomains
          }
          editModifyData(this.vUserId,data).then(res => {
            this.$message.success('修改成功')
            this.$store.commit('SET_vName', res.result.name)
            this.$store.commit('SET_vCompany', res.result.company)
            this.$store.commit('SET_vJob', res.result.job)
            this.$store.commit('SET_vDomains', res.result.domains)
            this.$router.replace('/my')
          })
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getDomains()
  }
}
</script>

<style lang="scss" scoped>
.modifyData {
  padding: 0 0.16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;
  h2 {
    font-size: 0.18rem;
    margin: 0.4rem 0;
  }
  .domainsBox {
    height: .34rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: 1px solid #e5e7e6;
    color: #c2c4c8;
    .checkedDomain {
      color: #000;
    }
    .domainList {
      span {
        display: inline-block;
        height: 0.22rem;
        line-height: 0.22rem;
        border: 1px solid #24bbd3;
        color: #24bbd3;
        font-size: 0.1rem;
        margin-left: .08rem;
        border-radius: 0.02rem;
        padding: 0 0.07rem;
      }
    }
  }
  .domainsDialog {
    left: 0;
    top: 0;
    img {
      display: block;
      width: 0.31rem;
      height: 0.31rem;
      padding: 0.16rem 0;
    }
    .el-checkbox-group {
      margin-bottom: 1rem;
      :deep(.el-checkbox) {
        width: 100%;
        margin-left: 0.2rem;
        height: 0.56rem;
        line-height: 0.56rem;
        font-size: 0.18rem;
        .el-checkbox__label {
          font-size: 0.18rem;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #24BBD3;
        }
      }
    }
  }
}
</style>